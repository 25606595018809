const ENV = window.APP_ENV?.DEPLOYMENT_ENV ?? 'localhost';

const envs = {
	localhost: {
		issuer: 'https://dev-73067812.okta.com/oauth2/default',
		clientId: '0oa1mjdvsoHVq18A05d7',
		redirectUri: 'http://localhost:3000/implicit/callback',
		logoutUri: 'http://localhost:3000/',
		scopes: ['openid', 'profile', 'email'],
		disableHttpsCheck: true,
		imagineLearnosityApiUrl: 'http://localhost:5000/v1/ImagineLearnosity',
		styledPreviewUrl: 'https://localcat.imaginelearning.com:4201/ela',
		itemCalibrationBaseURL: 'http://localhost:5001/v1.0/itembankparams/itemParameters',
	},
	qa: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oae2v3xnVpCEgbqX4x6',
		redirectUri: 'https://catnip-ui-qa.supplemental-nonprod.imaginelearning.com/implicit/callback',
		logoutUri: 'https://catnip-ui-qa.supplemental-nonprod.imaginelearning.com/',
		scopes: ['openid', 'profile', 'email'],
		disableHttpsCheck: false,
		imagineLearnosityApiUrl: 'https://testapi.imaginelearning.com/v1/ImagineLearnosity',
		styledPreviewUrl: 'https://testcat.imaginelearning.com/ela',
		itemCalibrationBaseURL: 'https://api-item-calibration-qa.supplemental-nonprod.imaginelearning.com/v1.0/itembankparams/itemParameters',
	},
	staging: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oae2u0u44eUwMoTs4x6',
		redirectUri: 'https://catnip-ui-staging.supplemental-nonprod.imaginelearning.com/implicit/callback',
		logoutUri: 'https://catnip-ui-staging.supplemental-nonprod.imaginelearning.com/',
		scopes: ['openid', 'profile', 'email'],
		disableHttpsCheck: false,
		imagineLearnosityApiUrl: 'https://rcapi.imaginelearning.com/v1/ImagineLearnosity',
		styledPreviewUrl: 'https://rccat.imaginelearning.com/ela',
		itemCalibrationBaseURL: 'https://api-item-calibration-staging.supplemental-nonprod.imaginelearning.com/v1.0/itembankparams/itemParameters',
	},
	prod: {
		issuer: 'https://weldnorthed.okta.com/oauth2/default',
		clientId: '0oae2wpi58oORgGbO4x6',
		redirectUri: 'https://catnip-ui-prod.supplemental-prod.imaginelearning.com/implicit/callback',
		logoutUri: 'https://catnip-ui-prod.supplemental-prod.imaginelearning.com/',
		scopes: ['openid', 'profile', 'email'],
		disableHttpsCheck: false,
		imagineLearnosityApiUrl: 'https://api.imaginelearning.com/v1/ImagineLearnosity',
		styledPreviewUrl: 'https://cat.imaginelearning.com/ela',
		itemCalibrationBaseURL: 'https://api-item-calibration-prod.supplemental-prod.imaginelearning.com/v1.0/itembankparams/itemParameters',
	},
};

export default function getEnv() {
	return envs[ENV];
}
