import React, { useState, useEffect, useRef } from 'react';
import Learnosity from '../../components/Learnosity/Learnosity';
import { useParams, useHistory } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import * as initService from '../../services/InitService';
import './ActivitiesContainer.scss';
import getEnv from '../../envs';

function ActivitiesContainer() {
	let [learnosityInit, setLearnosityInit] = useState(null);
	const { authState, authService } = useOktaAuth();
	const [userInfo, setUserInfo] = useState(null);
	const [authorApp, setAuthorApp] = useState(undefined);

	const internalRoute = useRef(false);

	let params = useParams();
	let history = useHistory();

	useEffect(() => {
		if (!authState.isAuthenticated) {
			// When user isn't authenticated, forget any user info
			setUserInfo(null);
		} else {
			authService.getUser().then((info) => {
				setUserInfo(info);
			});
		}
	}, [authState, authService]); // Update if authState changes

	useEffect(() => {
		if (userInfo) {
			setLearnosityInit(initService.getActivityListInit(window.location.hostname, userInfo));
		}
	}, [userInfo]); // Update if authState changes

	useEffect(() => {
		if (authorApp && params.activityReference) {
			// let ourselves know that we did the navigation.
			internalRoute.current = true;
			authorApp.navigate('activities/' + params.activityReference);
		}
	}, [authorApp, params]);

	function onNavigate(event) {

		if (event.data.route === 'activities/:reference' && !internalRoute.current) {
			event.preventDefault();
			let activityId = event.data.location.replace('/activities', '');
			history.push(activityId);
		}
		
		if (event.data.route === 'activities/:reference/items/:idOrReference') {
			event.preventDefault();
			let itemId = event.data.location.replace(/activities\/.*\/items\//, '');
			history.push('/items/' + itemId);
		}
	}

	const addPreviewButtons = () => {
		// add preview buttons for entire activity
		const activityPreviewButtons = document.querySelectorAll('.activity-preview');
		if (!activityPreviewButtons || !activityPreviewButtons.length) {
			const activityReference = encodeURIComponent(authorApp?.getActivity()?.reference);

			const toolbar = document.querySelector('.lrn-author-activity-toolbar .lrn-author-activity-toolbar-right ul');
			const kinderListElement = document.createElement('li');
			const uppperListElement = document.createElement('li');
			const activityPreviewClassList =
				'lrn-author-title-menu-element lrn-author-toolbar-button lrn-author-btn-outline lrn-btn-icon-left lrn-btn-responsive lrn-btn-i-xs lrn-btn activity-preview';

			const kinderPreview = document.createElement('button');
			kinderPreview.classList = activityPreviewClassList;
			kinderPreview.innerHTML = 'K';
			kinderPreview.id = 'activity-kinder-preview';
			kinderPreview.onclick = () => {
				window.open(`${getEnv().styledPreviewUrl}?activityId=${activityReference}&itemStyle=kinder`, '_blank');
			};
			kinderListElement.append(kinderPreview);

			const upperGradesPreview = document.createElement('button');
			upperGradesPreview.classList = activityPreviewClassList;
			upperGradesPreview.innerHTML = '2+';
			upperGradesPreview.onclick = () => {
				window.open(`${getEnv().styledPreviewUrl}?activityId=${activityReference}&itemStyle=2Plus`, '_blank');
			};
			uppperListElement.append(upperGradesPreview);

			toolbar.append(kinderListElement);
			toolbar.append(uppperListElement);
		}

		// add preview button for each item in activity
		const itemPreviewButtons = document.querySelectorAll('.styled-preview-button');
		if (!itemPreviewButtons || !itemPreviewButtons.length) {
			setTimeout(() => {
				const itemListHeaders = document.querySelectorAll(
					'.lrn-item-for-activity-list .lrn-list-view .lrn-list-view-heading-wrapper:first-child'
				);

				itemListHeaders.forEach((element) => {
					const itemReference = encodeURIComponent(element.querySelector('a')?.innerText);
					const upperGradesPreview = document.createElement('button');
					upperGradesPreview.classList = 'lrn-btn lrn-btn-primary styled-preview-button';
					upperGradesPreview.innerHTML = '2+';
					upperGradesPreview.onclick = () => {
						window.open(`${getEnv().styledPreviewUrl}?itemId=${itemReference}&itemStyle=2Plus`, '_blank');
					};
					element.prepend(upperGradesPreview);

					const kinderPreview = document.createElement('button');
					kinderPreview.classList = 'lrn-btn lrn-btn-primary styled-preview-button';
					kinderPreview.innerHTML = 'K';
					kinderPreview.onclick = () => {
						window.open(`${getEnv().styledPreviewUrl}?itemId=${itemReference}&itemStyle=kinder`, '_blank');
					};
					element.prepend(kinderPreview);
				});
			}, 100);
		}
	};

	const onActivityRendered = (event) => {
		const itemTab = document.querySelector(`[data-tab-id="items"].lrn-author-tab-link`);
		itemTab.onclick = (event) => {
			addPreviewButtons();
		};

		addPreviewButtons();
	};

	const onActivityListRendered = (event) => {
		const itemListHeaders = document.querySelectorAll('.lrn-activity-list .lrn-list-view .lrn-list-view-heading-wrapper:first-child');
		itemListHeaders.forEach((element) => {
			const activityId = encodeURIComponent(element.querySelector('a')?.innerText);
			const upperGradesPreview = document.createElement('button');
			upperGradesPreview.classList = 'lrn-btn lrn-btn-primary styled-preview-button';
			upperGradesPreview.innerHTML = '2+';
			upperGradesPreview.onclick = () => {
				window.open(`${getEnv().styledPreviewUrl}?activityId=${activityId}&itemStyle=2Plus`, '_blank');
			};
			element.prepend(upperGradesPreview);

			const kinderPreview = document.createElement('button');
			kinderPreview.classList = 'lrn-btn lrn-btn-primary styled-preview-button';
			kinderPreview.innerHTML = 'K';
			kinderPreview.onclick = () => {
				window.open(`${getEnv().styledPreviewUrl}?activityId=${activityId}&itemStyle=kinder`, '_blank');
			};
			element.prepend(kinderPreview);
		});
	};

	return (
			<Learnosity
				init={learnosityInit}
				navigate={onNavigate}
				getAuthorApp={(authorApp) => setAuthorApp(authorApp)}
				activityRendered={onActivityRendered}
				activityListRendered={onActivityListRendered}
			/>
	);
}

export default ActivitiesContainer;
